.wrapper {
    width: 100%;
    display: flex;
}

.sidebar {
    flex: 0 0 100px
}

.canvas {
    flex: 1 1 auto;
}

.playbook {
    width: 100%;
}

.timeline-container {
    background-color: #eee;
}

.label {
    width: 160px;
}

.timeline {
    padding: 5px;
    position: relative;
    border-left: 1px solid #666;
    height: 100%;
    width: calc(100% - 10px);
}

.action {
    position: relative;
    border-radius: 100px;
    padding: 2px 0;
    font-weight: 300;
    text-align: center;
    margin-bottom: 1px;
}

.action:nth-last-of-type() {
    margin-bottom: 0;
}

.action__drag-handle {
    margin: 0 0.5rem;
}

.player {
    stroke-width: 2px;
    stroke: white;
}

.player.is-selected {
    stroke: black;
}

.is-draggable {
    cursor: move;
}

.is-resizable {
    cursor: col-resize;
}

.is-static {
    cursor: not-allowed;
}

.place-marker {
    cursor: crosshair;
}

.animated-line {
    stroke-dasharray: 4;
    fill: none;
    stroke-width: 1px;
}

.icon-button {
    background-color: #444;
    color: white;
    border-radius: 100px;
    border: none;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    cursor: pointer;
}

.icon-button:hover,
.icon-button:active {
    background-color: #999;
}